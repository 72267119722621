<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-container class="px-0 pb-0">
            <v-row justify="center">
                <v-col cols="12" xl="12" class="pt-0">
                    <h1 class="page-header font-weight-light">Find Repeaters</h1>

                    <v-container class="px-0">
                        <v-row class="pb-3">
                            <v-col cols="7" sm="9" md="3">
                                <v-text-field
                                    v-model="searchFields.search"
                                    label="Search"
                                    autocomplete="off"
                                    hide-details
                                    clearable
                                    solo
                                >
                                    <v-icon slot="append" color="primary">mdi-magnify</v-icon>
                                </v-text-field>
                            </v-col>
                            <v-col cols="5" sm="3" md="2" class="text-center">
                                <v-btn-toggle v-model="searchFields.searchNearMe" color="primary" borderless>
                                    <v-btn class="elevation-2">
                                        <v-icon
                                            small
                                            class="mr-1"
                                            :color="searchFields.searchNearMe ? 'primary' : ($vuetify.theme.dark ? 'white' : 'almostblack')"
                                        >
                                            mdi-near-me
                                        </v-icon>
                                        Near Me
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col cols="12" sm="12" md="7">
                                <v-expansion-panels accordion>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header
                                            disable-icon-rotate class="font-weight-medium"
                                            color="highlight"
                                        >
                                            Advanced Search
                                            <template v-slot:actions>
                                                <v-icon>mdi-filter</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content color="highlight">
                                            <v-row>
                                                <v-col cols="12" sm="6" md="4" class="py-1">
                                                    <v-autocomplete
                                                        v-model="searchFields.frequency"
                                                        :items="frequencies"
                                                        label="Frequency"
                                                        autocomplete="off"
                                                        hide-details
                                                        clearable
                                                        solo
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4" class="py-1">
                                                    <v-autocomplete
                                                        v-model="searchFields.state"
                                                        :items="states"
                                                        label="State"
                                                        autocomplete="off"
                                                        hide-details
                                                        clearable
                                                        solo
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4" class="py-1">
                                                    <v-autocomplete
                                                        v-model="searchFields.type"
                                                        :items="types"
                                                        label="Repeater Type"
                                                        autocomplete="off"
                                                        hide-details
                                                        clearable
                                                        solo
                                                    ></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <div class="d-flex justify-space-around">
                                                        <v-switch
                                                            v-model="searchFields.showOldListings"
                                                            inset
                                                            dense
                                                            hide-details
                                                            color="primary"
                                                            label="Show Stale Repeaters"
                                                            prepend-icon="mdi-clock-alert-outline"
                                                        ></v-switch>
                                                        <v-switch
                                                            v-model="searchFields.showOfflineListings"
                                                            inset
                                                            dense
                                                            hide-details
                                                            color="primary"
                                                            label="Show Offline Repeaters"
                                                            prepend-icon="mdi-circle-off-outline"
                                                        ></v-switch>
                                                    </div>
                                                </v-col>
                                                <!--                                            <v-col cols="12" md="6">-->
                                                <!--                                                <v-switch-->
                                                <!--                                                        v-model="showOldListings"-->
                                                <!--                                                        inset-->
                                                <!--                                                        dense-->
                                                <!--                                                        hide-details-->
                                                <!--                                                        color="primary"-->
                                                <!--                                                        label="Show Stale Repeaters"-->
                                                <!--                                                        prepend-icon="mdi-clock-alert-outline"-->
                                                <!--                                                ></v-switch>-->
                                                <!--                                            </v-col>-->
                                                <!--                                            <v-col cols="12" md="6">-->
                                                <!--                                                <v-switch-->
                                                <!--                                                        v-model="showOfflineListings"-->
                                                <!--                                                        inset-->
                                                <!--                                                        dense-->
                                                <!--                                                        hide-details-->
                                                <!--                                                        color="primary"-->
                                                <!--                                                        label="Show Offline Repeaters"-->
                                                <!--                                                        prepend-icon="mdi-circle-off-outline"-->
                                                <!--                                                ></v-switch>-->
                                                <!--                                            </v-col>-->
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>

                        <v-alert type="error" colored-border border="left" dense v-if="error.state">{{
                                error.message
                            }}
                        </v-alert>

                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :loading="loading"
                            :options.sync="options"
                            :footer-props="{itemsPerPageOptions: [10, 20, 30, 40, 50]}"
                            :server-items-length="total"
                            :mobile-breakpoint="0"
                            sort-by="Modified"
                            sort-desc
                            @click:row="rowClick"
                            class="elevation-2"
                        >

                            <template v-slot:item.Name="{ item }">
                                <RepeaterStatusIcon :status="item.Status"/>

                                <span class="font-weight-medium">
                                <router-link
                                    :to="'/repeater/' + item.ID"
                                    :title="item.Name"
                                >{{ item.Name ? item.Name : item.Location }}</router-link>
                            </span>
                            </template>

                            <template v-slot:item.Location="{ item }">
                                <td class="text-truncate" style="max-width: 175px">
                                    <span :title="item.Location">{{ item.Location }}</span>
                                </td>
                            </template>

                            <template v-slot:item.Modified="{ item }">
                            <span :title="moment(item.Modified).format('MMM D, YYYY')">{{
                                    item.Modified | fromNow
                                }}</span>
                            </template>

                            <template v-slot:item.Owner="{ item }">
                                <router-link :to="'/user/' + item.Owner">{{ item.Owner }}</router-link>
                            </template>

                            <template v-slot:item.Frequency="{ item }">
                                {{ item.Frequency.replace('46X', '462') }} MHz
                            </template>
                        </v-data-table>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            top
        >
            {{ snackbar.text }}
        </v-snackbar>
    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import RepeaterStatusIcon from '@/components/RepeaterStatusIcon.vue';
    import config from '../config';
    import moment from 'moment';
    import axios from 'axios';
    import { useTitle } from '@vueuse/core';
    import frequencyList from '@/utils/frequencies';
    import typesList from '@/utils/repeaterTypes';
    import statesList from '@/utils/states';

    export default {
        name: 'Repeaters',

        components: {AdBTWR, RepeaterStatusIcon},

        props: ['user'],

        data: () => ({
            headers: [
                // {text: '', value: 'Status', sortable: false, width: 20},
                {text: 'Name', value: 'Name', width: 225},
                {text: 'City', value: 'Location', width: 175},
                {text: 'State', value: 'State', width: 80},
                {text: 'Type', value: 'Type', width: 170},
                {text: 'Frequency', value: 'Frequency', width: 120},
                {text: 'Tone In', value: 'PL In', width: 100},
                {text: 'Tone Out', value: 'PL Out', width: 100},
                {text: 'Updated', value: 'Modified', width: 120},
                // {text: 'Distance', value: 'Distance', width: 100},
                // {text: 'Owner', value: 'Owner'},
            ],
            searchFields: {
                search: null,
                searchNearMe: null,
                frequency: null,
                state: null,
                type: null,
                showOldListings: false,
                showOfflineListings: false,
                latitude: null,
                longitude: null,
            },
            snackbar: {
                state: false,
                color: 'info',
                text: ''
            },
            loading: false,
            options: {},
            total: 0,
            items: [],
            frequencies: frequencyList,
            types: typesList,
            states: statesList,
            error: {
                state: false,
                message: null
            }
        }),

        watch: {

            "user.authenticated"() {
                if (this.user.authenticated) {
                    this.fetchRepeaters();
                }
            },

            searchFields: {
                handler() {
                    if (this.searchFields) {
                        localStorage.setItem('repeaterSearch', JSON.stringify(this.searchFields));

                        if (this.searchFields.searchNearMe === 0 && (this.$_.isNull(this.searchFields.latitude) || this.$_.isNull(this.searchFields.longitude))) {
                            this.fetchUserLocation();
                        } else {
                            if (this.$_.isUndefined(this.searchFields.searchNearMe)) {
                                this.searchFields.latitude = null;
                                this.searchFields.longitude = null;
                                this.options.sortBy = ['Modified'];
                                this.options.sortDesc = [true];
                            } else {
                                this.options.sortBy = [];
                                this.options.sortDesc = [false];
                            }
                        }
                    }
                },
                deep: true
            },

            options: {
                handler() {
                    this.fetchRepeaters();
                },
                deep: true,
            },
        },

        methods: {
            fetchRepeaters() {
                if (!this.options.itemsPerPage) return;
                this.loading = true;

                //groupBy, groupDesc, mustSort, multiSort
                const offset = ((this.options.page - 1) * this.options.itemsPerPage);
                let query = config.API_LOCATION + `/repeaters?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                if (this.searchFields.search) query += `&search=${this.searchFields.search}`;
                if (this.searchFields.frequency) query += '&frequency=' + this.searchFields.frequency.replace('462', '46X');
                if (this.searchFields.state) query += `&state=${this.searchFields.state}`;
                if (this.searchFields.type) query += `&type=${this.searchFields.type}`;
                if (this.searchFields.showOldListings) query += `&outdated=true`;
                if (this.searchFields.showOfflineListings) query += `&offline=true`;
                if (this.searchFields.latitude) query += `&latitude=${this.searchFields.latitude}`;
                if (this.searchFields.longitude) query += `&longitude=${this.searchFields.longitude}`;
                if (this.searchFields.latitude && this.searchFields.longitude) query += `&range=25`; //default is 10

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(query, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.total = response.data.info.total;
                        this.items = response.data.items;

                        this.error.state = false;
                        this.error.message = null;
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            this.error.message = 'An error occurred while searching for repeaters.';
                            this.error.state = true;
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            fetchUserLocation() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.searchFields.latitude = position.coords.latitude;
                        this.searchFields.longitude = position.coords.longitude;

                        this.options.sortBy = [];
                        this.options.sortDesc = [false];

                        //force update because a watcher won't fire if the data didn't change
                    }, err => {
                        console.error(err.message);
                        // alert(`An error occurred while determining your location: ${err.message}`);
                        this.snackbar.text = `An error occurred while determining your location: ${err.message}`;
                        this.snackbar.color = 'error';
                        this.snackbar.state = true;
                        this.fetchRepeaters();
                    });
                } else {
                    alert('Geolocation is not supported by this browser.');
                }
            },

            rowClick(item) {
                this.$router.push({name: 'repeater', params: {id: item.ID}})
            },

            moment(date) {
                return moment(date);
            }
        },

        filters: {
            fromNow(date) {
                return moment(date).fromNow();
            }
        },

        mounted() {
            useTitle('Find Repeaters - myGMRS.com');

            try {
                const searchFields = JSON.parse(localStorage.getItem('repeaterSearch'));
                if (searchFields) this.searchFields = searchFields;
            } catch (err) {
                this.searchFields = {
                    search: null,
                    searchNearMe: null,
                    frequency: null,
                    state: null,
                    type: null,
                    showOldListings: false,
                    showOfflineListings: false,
                    latitude: null,
                    longitude: null,
                };
                console.error(err);
            }
        }
    };
</script>
